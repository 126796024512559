import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesQuiry: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Quiry guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_quiry.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Quiry guide & review</h1>
          <h2>A guide & review for Quiry in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="quiry" enablePopover />
        </div>
        <p>
          Quiry is a unit with poor eyesight, hence her disappointing combat
          performance. Her skills are very niché, and even then the values are
          too small to even make a noticeable impact. She seems to demonstrate a
          special predilection for defenders, increasing their attack and
          critical rate via Burst Skill. She also increases the HP of defenders,
          but it is currently not that useful, except in the case of 2B. Yet, it
          barely provides any boost, and bringing another B3 would be more
          justifiable. Attack reduction may come handy situationally for some
          content, both PvE and PvP. Finally, the last part of her kit is her
          overtime healing, which is a lot, but where would it be used?
        </p>
        <SectionHeader title="Usage" />
        <p>
          Her main place will be in teams where B3 healer is preferred over B1
          or B2. This frequently happens in Solo Raids, especially if you do not
          possess all the meta units. While her kit may be lackluster, if she
          allows you to survive for the whole 3 minutes, you will likely end up
          dealing more damage anyway.
        </p>
        <h5>Bossing</h5>
        <p>
          One potential usage for Quiry is in Modernia's team, where you run a
          1-1-3 formation. Unless you run a healer in B1 or B2, a healer in B3
          is often necessary for survival. In such cases, we usually resort to
          Helm, which not only provides healing every other rotation but also
          grants critical rate and ATK damage buff. With that said, Quiry can
          actually take over Helm's spot. The problem is that she does not have
          much to offer when it comes to offense.
        </p>
        <h5>PVP</h5>
        <p>
          She might usable in PvP as a copium unit. Her immense healing on Burst
          Skill is a great addition to stall teams, but stall teams have one
          major weakness — SAnis. If you consistently have the edge over someone
          (CP penalty), then with the right units, she might actually allow your
          team to survive a full 10-second SAnis' Burst Skill. However, other
          than that, you should deploy her with extreme caution. Since she is a
          B3 unit, it means you cannot use the skills of other B3, like Scarlet,
          SAnis, Alice, or 2B. This will result in lack of offensive firepower,
          and the only way you can win a battle is through surviving longer.
          Fortunately, her weaknesses should not be a major problem when
          attacking, as you can tweak teams according to the enemy's
          composition.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Team Compositions" />
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="quiry" enablePopover />
            </div>
          </div>
        </div>
        <p>
          One variant of the PvP stall team you can adopt. Add Jackal if
          necessary. This team is focused on healing and ensuring that each of
          your units stays alive through the entire enemy's critical attacking
          window.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="miranda" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dolla" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="quiry" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="modernia" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The popular solo Modernia team with Miranda that usually utilizes Helm
          as the main healer. This team is occasionally seen in Solo Raids,
          especially with players who are not blessed with every single meta
          unit. Quiry can be used as an alternative to Helm, but she is still
          inferior.{' '}
          <strong>
            Note that Quiry works in any 1-1-3 teams, or even 1-2-2 or 2-1-2
            teams, as long as you benefit from her heal. It does NOT have to be
            Modernia and follow this specific configuration.
          </strong>
        </p>
        <SectionHeader title="Should You Pull" />
        <Alert variant="primary" className="alert-update">
          <p>Should you pull? Not really.</p>
        </Alert>
        <p>
          Even if Anniversary weren't here, no. Her kit is just terrible. She is
          just a filler unit, so people can save gems. If you like her model and
          pose, then no one's stopping you from pulling at your own discretion.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    B3 healer is sometimes preferred over B1 or B2 healers
                  </li>
                  <li>Can substitute Helm</li>
                  <li>
                    Reduces enemy ATK, which may come handy in very specific
                    cases
                  </li>
                  <li>Huge overtime healing behind Burst Skill</li>
                  <li>Rocket Launcher provides good burst generation</li>
                  <li>Snake says she's very cute!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Lackluster and non-versatile kit</li>
                  <li>Overshadowed by many other units</li>
                  <li>
                    If you use her Burst Skill, you can't use other B3 units'
                    skills, which may be more powerful, especially in PvP
                  </li>
                  <li>Offensive buffs are limited to defenders</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesQuiry;

export const Head: React.FC = () => (
  <Seo
    title="Quiry guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Quiry in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
